import * as React from 'react';

export default function oralLiteratureResult({ result }) {
  return (
    <li className="sui-result">
      <div className="sui-result__header">
      <h3>{result.language_name.raw}</h3> 
      </div>
      <div className="sui-result__body">
        <ul className="sui-result__details">
        <li>
            <span style={{ fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif', fontSize: '15px' }}>ISO: </span>
            <span
              className="sui-result__value"
              dangerouslySetInnerHTML={{
                __html: result.iso.snippet
              }}
            />
          </li>
          <li>
            <span style={{ fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif', fontSize: '15px' }}>Total Speakers: </span>
            <span
              className="sui-result__value"
              dangerouslySetInnerHTML={{
                __html: result.total_speakers.snippet
              }}
            />
          </li>
        <li>
            <span style={{ fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif', fontSize: '15px' }}>Country: </span>
            <span
              className="sui-result__value"
              dangerouslySetInnerHTML={{
                __html: result.country.snippet
              }}
            />
          </li>
          <li>
            <span style={{ fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif', fontSize: '15px' }}>Continent: </span>
            <span
              className="sui-result__value"
              dangerouslySetInnerHTML={{
                __html: result.continent.snippet
              }}
            />
          </li>
          <li>
            <span style={{ fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif', fontSize: '15px' }}>UNESCO Status: </span>
            <span
              className="sui-result__value"
              dangerouslySetInnerHTML={{
                __html: result.unesco_status.raw
              }}
            />
          </li>
          <li>
            <span style={{ fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif', fontSize: '15px' }}>Sutherland Status: </span>
            <span
              className="sui-result__value"
              dangerouslySetInnerHTML={{
                __html: result.sutherland_status.raw
              }}
            />
          </li>
          <li>
            <span style={{ fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif', fontSize: '15px' }}>Ethnologue Status: </span>
            <span
              className="sui-result__value"
              dangerouslySetInnerHTML={{
                __html: result.ethnologue_status.raw
              }}
            />
          </li>
        </ul>
      </div>
    </li>

  );
}


